
import { defineComponent } from 'vue';
import { useRouter } from 'vue-router';
import EventResource from '@/resources/EventResource';
import Form from '@/components/Event/Form.vue';
import useForm from '@/composables/use-form';

export default defineComponent({
    components: { Form,},

    setup() {
        const router = useRouter();
        const form = useForm({}, EventResource);
        const { isSaving, save  } = form;

        function submit() {
            save().then(() => {
                router.go(-1);
            });
        }

        return {
            form,
            isSaving,
            submit,
        };
    },
});
